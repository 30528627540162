import * as Payments from './payments'

/**
 * Currency definition with hints for proper rendering.
 * @typedef {Object} CurrencyDefinition
 * @property {string} code
 *   Currency code according to ISO 4217.
 * @property {number} ratio
 *   Amount of cents in a single unit.
 * @property {boolean} [customCurrency]
 *   Whether currency is regular or our custom created
 */

const EUR = {
  code: 'EUR',
  customCurrency: false,
  ratio: 100,
  symbol: '€',
}

const NOK = {
  code: 'NOK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

const SEK = {
  code: 'SEK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

const USD = {
  code: 'USD',
  customCurrency: false,
  ratio: 100,
  symbol: '$',
}

const DKK = {
  code: 'DKK',
  customCurrency: false,
  ratio: 100,
  symbol: 'kr',
}

const INR = {
  code: 'INR',
  customCurrency: false,
  ratio: 100,
  symbol: '₹',
}

const CAD = {
  code: 'CAD',
  customCurrency: false,
  ratio: 100,
  symbol: 'C$',
}

const UBT = {
  code: 'UBT',
  customCurrency: true,
  ratio: 100000,
  symbol: 'm₿',
}

const JPY = {
  code: 'JPY',
  customCurrency: false,
  ratio: 100,
  symbol: '¥',
}

const BRL = {
  code: 'BRL',
  customCurrency: false,
  ratio: 100,
  symbol: 'R$',
}

export const currencies = {
  CAD: CAD,
  DKK: DKK,
  EUR: EUR,
  INR: INR,
  JPY: JPY,
  NOK: NOK,
  SEK: SEK,
  USD: USD,
  UBT: UBT,
  BRL: BRL,
}

/**
 * Transaction steps
 * Used to render correct components & flow-management
 * @constant {Object}
 */
export const TransactionStep = {
  PaymentMethod: 1,
  PaymentDetails: 2,
  Processing: 3,
  Pending: 7,
  Failure: 4,
  Success: 5,
  Cancel: 6,
}

/**
 * Acceptable transaction types
 * Used to error application if none of these are uesd
 * @constant {string[]}
 */
export const acceptableTransactionTypes = [
  Payments.TransactionType.AUTH,
  Payments.TransactionType.DEPOSIT,
  Payments.TransactionType.WITHDRAWAL,
]

export const languageIds = {
  de: 'de_DE',
  fi: 'fi_FI',
  pt: 'pt',
  sv: 'sv_SE',
  ja: 'jp',
}

export const PAYMERO_API_KEY = {
  staging: '9marJNcctAax4j9ncUUwC6Vb9DLx48dn7exiBxjl',
  production: 'XADpI7Y0Ja7dVcXxGWwrN8wIp97QLnWx9gAiZThA',
}

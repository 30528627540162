import * as PaymentPropTypes from '../../prop-types'
import * as R from 'ramda'
import * as commands from '../../commands'

import PropTypes from 'prop-types'
import React from 'react'
import {TransactionState} from '../../constants'
import {useDispatch} from 'react-redux'

export const TransactionContext = React.createContext()

function useDeepMemo(nextValue) {
  const value = React.useRef(nextValue)

  if (!R.equals(value.current, nextValue)) {
    value.current = nextValue
  }

  return value.current
}

/**
 * React Hook to query transaction status every second
 * @param {string} transactionId
 */
function useTransactionStatus(transactionId) {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (transactionId) {
      const timerId = window.setInterval(() => {
        dispatch(commands.requestTransactionUpdate({transactionId}))
      }, 1000)
      return () => window.clearInterval(timerId)
    }
  }, [transactionId])
}

export function TransactionProviderBase(props) {
  React.useEffect(() => {
    props.onCleanup()
    props.onInit()
  }, [props.transactionType, props.onCleanup, props.onInit])

  useTransactionStatus(props.transactionId)

  const transactionState = React.useRef(props.transactionState)
  React.useEffect(() => {
    if (transactionState.current !== props.transactionState) {
      transactionState.current = props.transactionState
      switch (props.transactionState) {
        case TransactionState.CANCELLED: {
          props.onCancel()
          break
        }
        case TransactionState.FAILED: {
          props.onFailure()
          break
        }
        // Treat WAITING_APPROVAL as success as no user action is required
        case TransactionState.WAITING_APPROVAL:
        case TransactionState.SUCCESSFUL: {
          props.onSuccess()
          break
        }
      }
    }
  }, [transactionState, props.transactionState])

  const context = useDeepMemo({
    attributes: props.attributes,
    mountPoint: props.mountPoint,
    redirectParams: props.redirectParams,
    transactionState: props.transactionState,
    transactionType: props.transactionType,
    onFailure: props.onFailure,
    onPerform: props.onPerform,
    onReset: props.onReset,
    onSuccess: props.onSuccess,
    onValidate: props.onValidate,
  })

  return (
    <TransactionContext.Provider value={context}>
      {props.children}
    </TransactionContext.Provider>
  )
}

TransactionProviderBase.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  mountPoint: PropTypes.string.isRequired,
  redirectParams: PropTypes.object,
  transactionId: PropTypes.string,
  transactionState: PaymentPropTypes.transactionState,
  transactionType: PaymentPropTypes.transactionType,
  onCancel: PropTypes.func.isRequired,
  onCleanup: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  onPerform: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export function useTransactionContext() {
  return React.useContext(TransactionContext)
}

// List of optional and required parameters by payment method,
// used to ensure that the request body will be built correctly
// by the middleware.
// Both optional and required parameters are marked as false. They
// are used to specify parameters to be included in the request to PiQ,
// but we do not enforce any params to be added (hence false).
export default {
  apco: {
    deposit: {
      service: false,
    },
  },
  astropaycard: {
    deposit: {
      service: false,
    },
  },
  astropaybank: {
    withdrawal: {
      accountId: false,
      bankCode: false,
      nationalId: false,
      bankBranch: false,
      bankAccount: false,
      accountType: false,
      service: false,
    },
  },
  cashlib: {
    deposit: {
      voucherNumber: false,
    },
  },
  creditcard: {
    deposit: {
      accountId: false,
      encCvv: false,
      cardHolder: false,
      encCreditcardNumber: false,
      expiryMonth: false,
      expiryYear: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      encCvv: false,
      cardHolder: false,
      encCreditcardNumber: false,
      expiryMonth: false,
      expiryYear: false,
    },
  },
  cryptocurrency: {
    deposit: {
      accountId: false,
      cryptoCurrency: false,
      network: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      cryptoCurrency: false,
      network: false,
      destinationTag: false,
      walletAddress: false,
      service: false,
    },
  },
  ecobanq: {
    deposit: {
      ecoBanqUserId: false,
      ecoBanqPassword: false,
    },
    withdrawal: {
      ecoBanqUserId: false,
    },
  },
  ezeebill: {
    withdrawal: {
      accountNumber: false,
      bankName: false,
      bankCode: false,
      branchName: false,
      branchCode: false,
      beneficiaryName: false,
      service: false,
    },
  },
  ideal: {
    deposit: {
      service: false,
    },
  },
  idebit: {
    withdrawal: {
      accountId: false,
    },
  },
  inovapaybank: {
    withdrawal: {
      accountId: false,
      service: false,
      nationalId: false,
      pixKey: false,
      branchCode: false,
      accountNumber: false,
      accountType: false,
    },
  },
  inovapaywallet: {
    deposit: {
      accountId: false,
      userLogin: false,
      userSecureId: false,
    },
    withdrawal: {
      accountId: false,
      userLogin: false,
      userSecureId: false,
    },
  },
  instadebit: {
    deposit: {
      accountId: false,
    },
    withdrawal: {
      accountId: false,
    },
  },
  iwallet: {
    deposit: {
      accountId: false,
      destinationAccount: false,
    },
    withdrawal: {
      accountId: false,
      destinationAccount: false,
    },
  },
  kluwp: {
    deposit: {
      service: false,
    },
  },
  luxonpay: {
    withdrawal: {
      accountId: false,
      recipientWalletId: false,
    },
  },
  skrill: {
    deposit: {
      accountId: false,
      email: false,
    },
    withdrawal: {
      accountId: false,
      email: false,
    },
  },
  skrillqco: {
    deposit: {
      accountId: false,
      account: false,
      email: false,
      secureId: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      service: false,
    },
  },
  mifinity: {
    deposit: {
      accountId: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      destinationAccount: false,
    },
  },
  neteller: {
    deposit: {
      accountId: false,
      account: false,
      secureId: false,
    },
    withdrawal: {
      accountId: false,
      account: false,
    },
  },
  ecopayz: {
    deposit: {
      accountId: false,
      accountNumber: false,
    },
    withdrawal: {
      accountId: false,
      accountNumber: false,
    },
  },
  venuspoint: {
    deposit: {
      accountId: false,
      username: false,
      password: false,
    },
    withdrawal: {
      accountId: false,
      username: false,
      password: false,
    },
  },
  vega: {
    deposit: {
      accountId: false,
      username: false,
      password: false,
    },
    withdrawal: {
      accountId: false,
      username: false,
      password: false,
    },
  },
  paramount: {
    withdrawal: {
      securityQuestion: false,
      securityAnswer: false,
    },
  },
  swish: {
    deposit: {
      accountId: false,
      swishNumber: false,
    },
  },
  zimpler: {
    deposit: {
      accountId: false,
      phoneNumber: false,
    },
    withdrawal: {
      accountId: false,
      phoneNumber: false,
    },
  },
  pay4fun: {
    withdrawal: {
      accountId: false,
      email: false,
    },
  },
  paymerobank: {
    withdrawal: {
      accountId: false,
      accountNumber: false,
      beneficiaryName: false,
      bankName: false,
      service: false,
      ifscCode: false,
    },
  },
  payretailers: {
    withdrawal: {
      accountId: false,
      bankName: false,
      accountAgencyNumber: false,
      accountNumber: false,
      documentNumber: false,
      documentType: false,
      accountType: false,
      beneficiaryName: false,
      recipientPixKey: false,
      service: false,
    },
  },
  ppro: {
    deposit: {
      service: false,
    },
  },
  pugglepay: {
    deposit: {
      accountId: false,
      phoneNumber: false,
    },
    withdrawal: {
      accountId: false,
      phoneNumber: false,
    },
  },
  bank: {
    deposit: {
      accountId: false,
      nationalId: false,
      service: false,
      bankName: false,
      upi: false,
    },
    withdrawal: {
      accountId: false,
      service: false,
    },
  },
  bankdomestic: {
    withdrawal: {
      accountId: false,
      accountNumber: false,
      beneficiaryName: false,
      bankName: false,
      countryCode: false,
    },
  },
  banklocal: {
    withdrawal: {
      accountId: false,
      countryCode: false,
      clearingNumber: false,
      accountNumber: false,
      beneficiaryName: false,
      bankName: false,
      branchName: false,
      localBeneficiaryName: false,
      service: false,
    },
  },
  bankiban: {
    deposit: {
      bic: false,
      iban: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      bankName: false,
      countryCode: false,
      bic: false,
      iban: false,
      beneficiaryName: false,
      service: false,
    },
  },
  bestpaytransfer: {
    withdrawal: {
      accountId: false,
      accountNumber: false,
      branchCode: false,
    },
  },
  bankintl: {
    withdrawal: {
      accountId: false,
      bic: false,
      accountNumber: false,
      beneficiaryName: false,
      beneficiaryStreet: false,
      beneficiaryZip: false,
      beneficiaryCity: false,
      beneficiaryState: false,
      beneficiaryCountry: false,
      bankName: false,
      branchName: false,
      branchAddress: false,
      branchCode: false,
      service: false,
    },
  },
  sofort: {
    deposit: {
      accountId: false,
      service: false,
    },
  },
  sticpay: {
    withdrawal: {
      email: false,
      accountId: false,
    },
  },
  muchbetter: {
    deposit: {
      accountId: false,
      phoneNumber: false,
    },
    withdrawal: {
      accountId: false,
      phoneNumber: false,
    },
  },
  trustly: {
    deposit: {
      accountId: false,
    },
    withdrawal: {
      accountId: false,
    },
  },
  interac: {
    withdrawal: {
      accountId: false,
      mobile: false,
      email: false,
    },
  },
  interkassawallet: {
    deposit: {
      accountId: false,
      walletNumber: false,
      service: false,
    },
    withdrawal: {
      accountId: false,
      walletNumber: false,
      service: false,
    },
  },
  webredirect: {
    deposit: {
      email: false,
      service: false,
    },
    withdrawal: {
      email: false,
      service: false,
    },
  },
  gate2waywallet: {
    withdrawal: {
      walletNumber: false,
      walletName: false,
      service: false,
    },
  },
}

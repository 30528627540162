import * as Configuration from './configuration'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import {defaultTheme} from './theme'

const OVERRIDES = {
  casitabi: {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      background: 'transparent',
      muted: '#3765af',
      navBackground: '#2653a1',
      paymentMethodText: '#343a40',
      primary: '#00ca1b',
      formLabel: '#ffffff',
      text: '#ffffff',
    },
    buttons: {
      ...defaultTheme.buttons,
      primary: {
        ...defaultTheme.buttons.primary,
        borderRadius: '9999px',
      },
    },
    forms: {
      ...defaultTheme.forms,
      input: {
        ...defaultTheme.forms.input,
        color: 'paymentMethodText',
      },
      select: {
        ...defaultTheme.forms.select,
        color: 'paymentMethodText',
      },
    },
  },
  nolimitcasino: {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      background: 'transparent',
      muted: '#005236',
      navBackground: '#0B2A32',
      paymentMethodText: '#F8F7F1',
      primary: '#009966',
      formLabel: '#C8CCC9',
      text: '#F8F7F1',
    },
    buttons: {
      ...defaultTheme.buttons,
      primary: {
        ...defaultTheme.buttons.primary,
        'px': 2,
        'borderRadius': '4px',
        'boxShadow':
          '0px 1px 8px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.25)',
        'textShadow': '0px 1px 0px rgba(0, 0, 0, 0.25)',
        'height': '44px',
        '&:hover': {
          background: 'hsl(160, 100%, 34%)',
        },
      },
    },
    forms: {
      ...defaultTheme.forms,
      input: {
        ...defaultTheme.forms.input,
        'color': 'paymentMethodText',
        'backgroundColor': '#072027',
        '&:focus': {
          borderColor: 'paymentMethodText',
          outline: 'none',
        },
      },
      select: {
        ...defaultTheme.forms.select,
        color: 'paymentMethodText',
        backgroundColor: '#072027',
      },
    },
  },
}

export function BrandThemeProvider(props) {
  const brand = ReactRedux.useSelector(Configuration.getBrandingId)
  const theme = OVERRIDES[brand] || defaultTheme

  return (
    <ThemeUi.ThemeProvider theme={theme}>
      {props.children}
    </ThemeUi.ThemeProvider>
  )
}

BrandThemeProvider.propTypes = {
  children: PropTypes.node,
}
